<template>
    <div class="fill-height" v-bind:style="style">
        <v-container class="fill-height">
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="6" lg="4">
                    <UserLoginCard />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import UserLoginCard from '../components/login/UserLoginCard.vue';

export default {
    name: 'LoginUser',

    components: {
        UserLoginCard
    },

    computed: {
        style() {
            const style = {};
            let image = null;

            switch (this.$store.state.application.providerCode) {
                case 'europeanhomes':
                    image = require('@/assets/europeanhomes/homepage.png');
                    break;
            }

            if (image !== null && !this.$vuetify.breakpoint.mobile) {
                style.backgroundImage = `url("${image}")`;
                style.backgroundSize = 'contain';
                style.backgroundPosition = 'center';
            }

            return style;
        }
    }
};
</script>
